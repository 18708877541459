import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-classes"></a><h2>Psionic Classes
    </h2>
    <a id="psychic-warrior"></a><h3>PSYCHIC WARRIOR
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80448.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><a href="http://www.wizards.com/dnd/images/xph_gallery/33174.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Any.
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8.</p>
    <h6>Class Skills</h6>
The psychic warrior&#8217;s class skills (and the key ability for each skill)
are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a>* (Wis), <a href="skillsAll.html#climb" style={{
      "color": "rgb(87, 158, 182)"
    }}>Climb</a>
(Str), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a>* (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft </a>(Int),
    <a href="skillsAll.html#jump" style={{
      "color": "rgb(87, 158, 182)"
    }}>Jump</a>
(Str), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a>* (Int), <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a>
(Wis), <a href="skillsAll.html#ride" style={{
      "color": "rgb(87, 158, 182)"
    }}>Ride</a>
(Dex), <a href="skillsAll.html#search" style={{
      "color": "rgb(87, 158, 182)"
    }}>Search</a> (Int), and <a href="skillsAll.html#swim" style={{
      "color": "rgb(87, 158, 182)"
    }}>Swim</a>
(Str).
*New skill or expanded use of existing skill.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at 1st Level:</span>
(2 + Int modifier) x4.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier.</p>
    <a id="table-the-psychic-warrior"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Psychic Warrior
    </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="1" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="1" style={{
            "width": "24%"
          }}>Special</th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Power Points/Day</span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Powers Known</span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Maximum Power Level Known</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
          <td>Bonus feat
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>0*
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
          <td>&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>23
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>27
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>43
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>12
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>51
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>13
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+9</td>
          <td>+4</td>
          <td>+4</td>
          <td>&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>59
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+9</td>
          <td>+5</td>
          <td>+5</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>67
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>79
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>16
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>91
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>103
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>18
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>&nbsp;-</td>
          <td style={{
            "verticalAlign": "top"
          }}>115
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">&nbsp;Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>127
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="9">*<span style={{
              "fontStyle": "italic"
            }}>The
psychic warrior gains no power points from his class at 1st level.
However, he does add any bonus power points he gains from a high Wisdom
score, his race, and feats or other sources to his reserve. He can use
these points (if any) to manifest his power. </span>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psychic-warrior-class-features"></a><h6>Class Features</h6>
All the following are class features of the psychic warrior.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Psychic warriors are proficient with all simple and martial weapons,
with all types of armor (heavy, medium, and light), and with shields
(except tower shields).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Power Points/Day:</span> A
psychic warrior&#8217;s ability to manifest powers is limited by the power
points he has available. His base daily allotment of power points is
given on Table: The Psychic Warrior. In addition, he receives bonus
power points per day if he has a high Wisdom score (see Table: Ability
Modifiers and Bonus Power Points). His race may also provide bonus
power points per day, as may certain feats and items. A 1st-level
psychic warrior gains no power points for his class level, but he gains
bonus power points (if he is entitled to any), and can manifest the
single power he knows with those power points.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> A
psychic warrior begins play knowing one psychic warrior power of your
choice. Each time he achieves a new level, he unlocks the knowledge of
a new power.
Choose the powers known from the <a href="psionicWarriorList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic warrior power list</a>.
(Exception: The feats <a href="psionicFeats.html#expanded-knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Expanded Knowledge</a> and <a href="epicFeats.html#epic-expanded-knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Epic Expanded Knowledge</a> do
allow a psychic warrior to learn powers from the lists of other
classes.) A psychic warrior can manifest any power that has a power
point cost equal to or lower than his manifester level.
The total number of powers a psychic warrior can manifest in a day is
limited only by his daily power points.
A psychic warrior simply knows his powers; they are ingrained in his
mind. He does not need to prepare them (in the way that some
spellcasters prepare their spells), though he must get a good night&#8217;s
sleep each day to regain all his spent power points.
The Difficulty Class for saving throws against psychic warrior powers
is 10 + the power&#8217;s level + the psychic warrior&#8217;s Wisdom modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Maximum Power Level Known:</span>
A psychic warrior begins play with the ability to learn 1st-level
powers. As he attains higher levels, he may gain the ability to master
more complex powers.
To learn or manifest a power, a psychic warrior must have a Wisdom
score of at least 10 + the power&#8217;s level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> At 1st
level, a psychic warrior gets a bonus combat-oriented feat in addition
to the feat that any 1st level character gets and the bonus feat
granted to a human character. The psychic warrior gains an additional
bonus feat at 2nd level and every three levels thereafter (5th, 8th,
11th, 14th, 17th, and 20th). These bonus feats must be drawn from the
feats noted as <a href="featsFtb.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>fighter bonus feats</a> or psionic
feats. The psychic
warrior must still meet all prerequisites for the bonus feat, including
ability score and base attack bonus minimums as well as class
requirements. A psychic warrior cannot choose feats that specifically
require levels in the fighter class unless he is a multiclass character
with the requisite levels in the fighter class.
These bonus feats are in addition to the feats that a character of any
class gains every three levels. A psychic warrior is not limited to
fighter bonus feats and psionic feats when choosing these other feats.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      